import { template as template_25862121532c406fa4c9e9e21818eb09 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_25862121532c406fa4c9e9e21818eb09(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
