import { template as template_bb8914b3f8174615bb8189c6ec53e833 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bb8914b3f8174615bb8189c6ec53e833(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
