import { template as template_ddc7dd9a5b1d4ce888a810a688784880 } from "@ember/template-compiler";
const FKText = template_ddc7dd9a5b1d4ce888a810a688784880(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
